<template>
  <v-container>
    <v-layout row wrap justify-center my-5>
      <v-row>
        <v-col cols="3">
          <v-card max-width="400" tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><h2>Column Mapping</h2></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <p>
                  Review the current mapping of IRS fields to uploaded headers
                  and/or edit these mappings.
                </p>
              </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
              <v-list-item-content>
                <v-row align="center" justify="center">
                  <v-col cols="6" justify="center" align="center">
                    <v-btn @click="$router.push('/reviewcolmap')">Review</v-btn>
                  </v-col>
                  <v-col cols="6" align="center">
                    <v-btn @click="$router.push('/colmap')">Edit</v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card max-width="400" tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><h2>Combined State/Federal Codes</h2></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <p>Update the combined state/federal code mapping table.</p>
              </v-list-item-content>
            </v-list-item>
            <v-row align="center" justify="center">
              <v-col cols="6" align="center">
                <v-btn id="edit-state-btn" @click="editStateCode">Edit</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card max-width="400" tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><h2>T Record</h2></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <p>Update the data in the T record</p>
              </v-list-item-content>
            </v-list-item>
            <v-row align="center" justify="center">
              <v-col cols="6" align="center">
                <v-btn @click="editTrecord">Edit</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card max-width="400" tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><h2>A Record</h2></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <p>Update the data in the A record</p>
              </v-list-item-content>
            </v-list-item>
            <v-row align="center" justify="center">
              <v-col cols="6" align="center">
                <v-btn @click="editArecord">Edit</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
    <v-row justify="center">
      <v-dialog v-model="statecodesDialog" persistent max-width="600px">
        <v-card>
          <v-row>
            <div class="text-h4 pl-10 pt-5">State Codes List</div>
          </v-row>
          <v-row class="pl-5">
            <v-col>
              <div v-for="i in statecode1" :key="i['Id']">
                {{ i["State"] }} - {{ i["Code"] }}
              </div>
            </v-col>
            <v-col>
              <div v-for="i in statecode2" :key="i['Id']">
                {{ i["State"] }} - {{ i["Code"] }}
              </div>
            </v-col>
          </v-row>
          <v-row class="pl-5 mt-0 pb-5">
            <v-col cols="5">
              <v-text-field
                v-model="stateAdd"
                label="State"
                :rules="stateRules"
                hint="Must be all letters and not in list"
              ></v-text-field>
              <v-text-field
                v-model="codeAdd"
                :rules="codeRules"
                label="Code"
                hint="Must be all numbers and not in list"
              ></v-text-field>
              <v-btn
                id="state-add-btn"
                class="mt-5"
                :disabled="addDisable"
                @click="updateStatecode"
              >
                Add</v-btn
              >
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="5">
              <v-text-field
                v-model="stateDelete"
                label="State"
                :rules="stateRules"
                hint="Must be in list"
              ></v-text-field>
              <v-btn
                id="state-delete-btn"
                class="mt-5"
                :disabled="deleteDisable"
                @click="deleteStatecode"
              >
                Delete</v-btn
              >
            </v-col>
            <v-btn
              color="blue darken-1"
              text
              bottom
              right
              absolute
              @click="statecodesDialog = false"
            >
              Close
            </v-btn>
          </v-row>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="tRecordDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">T Records</span>
          </v-card-title>
          <v-card-text>
            <v-row v-for="i in tRecords" :key="i['Id']">
              <v-text-field
                v-if="i['Value'] != ''"
                v-model="i['Value']"
                :label="i['IRSName']"
              >
              </v-text-field>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="tRecordDialog = false">
              Close
            </v-btn>
            <v-btn
              id="t-record-save-btn"
              color="blue darken-1"
              text
              @click="updateTrecord"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="aRecordDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">A Records</span>
          </v-card-title>
          <v-card-text>
            <v-row v-for="i in aRecords" :key="i['Id']">
              <v-text-field
                v-if="i['Value'] != ''"
                v-model="i['Value']"
                :label="i['IRSName']"
              >
              </v-text-field>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="aRecordDialog = false">
              Close
            </v-btn>
            <v-btn
              id="a-record-save-btn"
              color="blue darken-1"
              text
              @click="updateArecord"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({
    statecodesDialog: false,
    tRecordDialog: false,
    aRecordDialog: false,
    statecodes: {},
    tRecords: {},
    aRecords: {},
    stateDelete: "",
    stateAdd: "",
    codeAdd: "",
    stateRules: [(v) => v.length == 2 || "State must be 2 characters"],
    codeRules: [(v) => v.length == 2 || "Code must be 2 characters"],
    statecode1: [],
    statecode2: [],
  }),
  computed: {
    deleteDisable() {
      if (this.stateDelete.length != 2) {
        return true
      } else {
        for (let i of this.statecodes) {
          if (i["State"] == this.stateDelete.toUpperCase()) {
            return false
          }
        }
        return true
      }
    },
    addDisable() {
      if (this.stateAdd.length != 2 || this.codeAdd.length != 2) {
        return true
      } else {
        if (/^\d+$/.test(this.codeAdd) && /^[a-zA-Z]+$/.test(this.stateAdd)) {
          for (let i of this.statecodes) {
            if (
              i["State"] == this.stateAdd.toUpperCase() ||
              i["Code"] == this.codeAdd
            ) {
              return true
            }
          }
          return false
        }
        return true
      }
    },
  },
  methods: {
    editStateCode() {
      this.$api.get("/statecode/edit/").then((response) => {
        this.statecodesDialog = true
        this.statecodes = response.data
        this.statecode1 = this.statecodes.slice(0, this.statecodes.length / 2)
        this.statecode2 = this.statecodes.slice(
          this.statecodes.length / 2,
          this.statecodes.length,
        )
      })
    },
    editTrecord() {
      this.$api.get("/trecord/edit").then((response) => {
        this.tRecordDialog = true
        let notNeeded = [
          "PaymentYear",
          "RecordType",
          "RecordSequenceNumber",
          "PriorYearDataIndicator",
          "TestFileIndicator",
          "RecordSequenceNumber",
        ]
        this.tRecords = response.data
        this.tRecords = this.tRecords.filter(
          (item) => !notNeeded.includes(item["IRSName"]),
        )
      })
    },
    editArecord() {
      this.$api.get("/arecord/edit").then((response) => {
        this.aRecordDialog = true
        let notNeeded = ["RecordType", "PaymentYear", "RecordSequenceNumber"]
        this.aRecords = response.data
        this.aRecords = this.aRecords.filter(
          (item) => !notNeeded.includes(item["IRSName"]),
        )
      })
    },
    updateStatecode() {
      let data = { state: this.stateAdd.toUpperCase(), code: this.codeAdd }
      this.$api.post("statecode/edit/", data).then((response) => {
        this.statecodesDialog = false
        this.editStateCode()
      })
    },
    updateTrecord() {
      this.$alert
        .fire({
          title: "WARNING!",
          text: "Are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f19e1f",
          cancelButtonColor: "#D9D9D9",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.tRecordDialog = false
            let formData = new FormData()
            formData.append("trecord", JSON.stringify(this.tRecords))
            this.$api.post("trecord/edit", formData)
          }
        })
    },
    updateArecord() {
      this.$alert
        .fire({
          title: "WARNING!",
          text: "Are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f19e1f",
          cancelButtonColor: "#D9D9D9",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.aRecordDialog = false
            let formData = new FormData()
            formData.append("arecord", JSON.stringify(this.aRecords))
            this.$api.post("arecord/edit", formData)
          }
        })
    },
    deleteStatecode() {
      let data = { state: this.stateDelete.toUpperCase() }
      this.$api.post("statecode/edit/", data).then((response) => {
        this.statecodesDialog = false
        this.editStateCode()
      })
    },
  },
}
</script>

<style></style>
